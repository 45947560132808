import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/locations';

export default {
  get(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  getLocations(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createLocation(data) {
    return axios.post(`${baseUrl}`, data);
  },
  delete(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
  update(id, data) {
    return axios.put(`${baseUrl}/${id}`, data);
  },
};
