<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateLocation)">
        <section class="mb-2">
          <div>
            <label>Name</label>
            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, min: 3 }"
            >
              <b-form-input
                v-model="new_location.name"
                :state="getValidationState(validationContext)"
                class="mb-1"
                placeholder="Enter name"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Apply Volumetric Controls</label>
            <b-form-select v-model="new_location.apply_volumetric_controls">
              <b-form-select-option selected :value="true">Yes</b-form-select-option>
              <b-form-select-option :value="false">No</b-form-select-option>
            </b-form-select>
          </div>

          <div v-if="new_location.apply_volumetric_controls">
            <label>Max Items</label>
            <validation-provider
              v-slot="validationContext"
              name="Max Items"
              :rules="{ required: true, min: -1 }"
            >
              <b-form-input
                v-model="new_location.max_items"
                type="number"
                class="mb-1"
                placeholder="Max Items"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Requires Possession Owner ID</label>
            <b-form-select v-model="new_location.requires_possession_owner_id">
              <b-form-select-option selected :value="true">Yes</b-form-select-option>
              <b-form-select-option :value="false">No</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Enable Possession Requests To</label>
            <b-form-select v-model="new_location.enable_possession_requests_to">
              <b-form-select-option selected :value="true">Yes</b-form-select-option>
              <b-form-select-option :value="false">No</b-form-select-option>
            </b-form-select>
          </div>

          <div>
            <label>Enable Possession Requests From</label>
            <b-form-select v-model="new_location.enable_possession_requests_from">
              <b-form-select-option selected :value="true">Yes</b-form-select-option>
              <b-form-select-option :value="false">No</b-form-select-option>
            </b-form-select>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button type="submit" variant="primary">Update Location</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import LocationsService from "@/services/LocationsService";

export default {
  components: {},
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  emits: ['refresh', 'close'],
  data() {
    return {
      new_location: {...this.$props.location},
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateLocation() {
      LocationsService.update(this.new_location.id, {
        name: this.new_location.name.trim(),
        max_items: this.new_location.apply_volumetric_controls ? parseInt(this.new_location.max_items, 10) : -1,
        apply_volumetric_controls: this.new_location.apply_volumetric_controls,
        requires_possession_owner_id: this.new_location.requires_possession_owner_id,
        enable_possession_requests_to: this.new_location.enable_possession_requests_to,
        enable_possession_requests_from: this.new_location.enable_possession_requests_from,
      }).then(() => {
        this.$toast.success(`Updated location successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.$emit('refresh')
        this.$emit('close', true);
      }).catch(err => {
        const res = err.response;
        let errorText = 'Could not update location, please refresh and try again';

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
  },
};
</script>
