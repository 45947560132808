<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="users-page">

    <b-card class="" title="Locations">
      <b-card-text>This page shows all locations available to your enterprise. You can manage and create locations
        directly from this page.
      </b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>

      <div class="m-2">
        <b-row>
          <b-col v-if="$can('Create', 'Owner')" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                 cols="12" lg="2"
                 md="3">
            <b-button variant="primary" @click="$bvModal.show('modal-add-location')">Create Location</b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" lg="10" md="9">
            <div class="d-flex align-items-center justify-content-end">

              <b-input-group>
                <b-form-input
                  v-model="searchVal"
                  class="d-inline-block"
                  placeholder="Search for a location..."
                  @keydown.enter="searchLocations()"
                />
                <b-input-group-append>
                  <b-button @click="searchLocations()">Search</b-button>
                </b-input-group-append>
              </b-input-group>

              <section class="col-2 p-0 ml-1 d-flex justify-content-end align-items-center">
                <b-button @click="clearFilters()">Clear filters</b-button>
              </section>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :current-page="currentPage"
        :fields="headers"
        :items="locations"
        :per-page="0"
        class="data-table"
        hover
        @sort-changed="onSortChanged"
      >
        <template #cell(name)="data">
          <router-link :to="{ name: 'location-details', params: { id: data.item.id } }"
                       class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </router-link>
        </template>
        <template #cell(max_items)="data">
          <span v-if="data.item.max_items > -1">{{data.item.max_items}}</span>
          <span v-else>No Limit</span>
        </template>
        <template #cell(actions)="data">
          <b-dropdown class="m-0" no-caret right toggle-class="text-decoration-none" variant="link">
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <router-link :to="{ name: 'location-details', params: { id: data.item.id } }">
              <b-dropdown-item-button class="full-nested-btn">
                  View
              </b-dropdown-item-button>
            </router-link>
            <b-dropdown-item-button v-if="$can('Update', 'Locations') && !data.item.system"
                                    class="full-nested-btn"
                                    @click="showModal(data.item, 'modal-update-location')">
              Update location
            </b-dropdown-item-button>
            <b-dropdown-item-button v-if="$can('Delete', 'Locations') && !data.item.system"
                                    class="full-nested-btn"
                                    @click="showModal(data.item, 'modal-delete-category')">
              Delete location
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row>
        <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
          <b-pagination v-model="currentPage" :per-page="filters.page_size" :total-rows="total_count"
                        class="my-0" @change="getMoreLocations"/>
        </b-col>
        <b-col v-if="total_count === 0" class="p-2 pl-3">No locations found</b-col>
      </b-row>

    </b-card>

    <b-modal id="modal-add-location" hide-footer title="Add new location">
      <add-location @close="closeModals()" @refresh="refreshLocations"></add-location>
    </b-modal>

    <b-modal id="modal-update-location" hide-footer title="Update location">
      <update-location
        :location="targetLocation"
        v-if="targetLocation"
        @close="closeModals()"
        @refresh="refreshLocations()"></update-location>
    </b-modal>

    <b-modal id="modal-delete-category" hide-footer title="Delete location">
      <delete-modal
        title="Are you sure you wish to delete this location"
        @close="closeModals()"
        @delete="deleteLocation(targetLocation)"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import DeleteModal from "@/components/modals/DeleteModal.vue";
import LocationsService from "@/services/LocationsService";
import AddLocation from "@/views/locations/modals/AddLocation.vue";
import UpdateLocation from "@/views/locations/modals/UpdateLocation.vue";

export default {
  name: 'PossessionCategories',
  components: {
    UpdateLocation,
    AddLocation,
    DeleteModal,
  },
  props: {},
  data() {
    return {
      targetLocation: null,
      headers: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'apply_volumetric_controls',
          label: 'Volumetric Controls',
        },
        {
          key: 'requires_possession_owner_id',
          label: 'Requires Owner',
        },
        {
          key: 'enable_possession_requests_to',
          label: 'Enable Requests To',
        },
        {
          key: 'enable_possession_requests_from',
          label: 'Enable Requests From',
        },
        {
          key: 'max_items',
          label: 'Max Items',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      locations: [],
      currentPage: 1,
      searchVal: '',
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler: function (newVal) {
        let queryString = `?page=${newVal.page + 1}`;
        if (newVal.search !== '') {
          queryString += `&search=${newVal.search}`
        }
        history.replaceState({}, null, `${this.$route.path}${queryString}`)
      }
    }
  },
  mounted() {
    // Set filters from query params
    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page, 10)
      this.filters.page = this.currentPage - 1
    }
    if (this.$route.query.search) {
      this.filters.search = this.$route.query.search
    }
    this.getLocations();
  },
  methods: {
    onSortChanged({sortBy, sortDesc}) {
      this.filters.order_by = sortDesc ? 'desc' : 'asc'
      this.filters.order = sortBy
      this.searchLocations()
    },
    clearFilters() {
      this.currentPage = 1
      this.searchVal = ''
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      }
      this.searchLocations()
    },
    refreshLocations() {
      this.closeModals();
      this.getLocations();
    },
    closeModals() {
      this.$bvModal.hide('modal-add-location');
      this.$bvModal.hide('modal-delete-category');
      this.$bvModal.hide('modal-update-location');
    },
    searchLocations() {
      this.filters.search = this.searchVal
      this.currentPage = 1;
      this.filters.page = 0;
      this.getLocations();
    },
    getMoreLocations(val) {
      this.filters.page = val - 1;
      this.getLocations();
    },
    getLocations() {
      LocationsService.getLocations(this.filters).then(res => {
        this.locations = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get categories, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    showModal(category, modalName) {
      this.targetLocation = category;
      this.$bvModal.show(modalName);
    },
    deleteLocation(location) {
      LocationsService.delete(location.id).then(() => {
        this.$toast.success(`Deleted location ${location.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.refreshLocations();
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not delete location, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
  },
};
</script>
