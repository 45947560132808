<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(addLocation)">
        <section class="mb-2">
          <div>
            <label>Name</label>
            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, min: 1 }"
            >
              <b-form-input
                v-model="location.name" class="mb-1" placeholder="Enter name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Apply Volumetric Controls</label>
            <b-form-select v-model="location.apply_volumetric_controls">
              <b-form-select-option selected value="true">Yes</b-form-select-option>
              <b-form-select-option value="false">No</b-form-select-option>
            </b-form-select>
          </div>

          <div v-if="location.apply_volumetric_controls === 'true'">
            <label>Max Items</label>
            <validation-provider
              v-slot="validationContext"
              name="Max Items"
              step="1"
              :rules="{ required: true, min: -1 }"
            >
              <b-form-input
                v-model="location.max_items"
                type="number"
                class="mb-1"
                placeholder="Max Items"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Requires Possessions To Belong To A Owner</label>
            <b-form-select v-model="location.requires_possession_owner_id">
              <b-form-select-option selected value="true">Yes</b-form-select-option>
              <b-form-select-option value="false">No</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Enable Possession Requests To</label>
            <b-form-select v-model="location.enable_possession_requests_to">
              <b-form-select-option selected value="true">Yes</b-form-select-option>
              <b-form-select-option value="false">No</b-form-select-option>
            </b-form-select>
          </div>

          <div>
            <label>Enable Possession Requests From</label>
            <b-form-select v-model="location.enable_possession_requests_from">
              <b-form-select-option selected value="true">Yes</b-form-select-option>
              <b-form-select-option value="false">No</b-form-select-option>
            </b-form-select>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit" :disabled="invalid">Add new location</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import LocationsService from "@/services/LocationsService";

export default {
  components: {},
  data() {
    return {
      location: {
        name: '',
        max_items: undefined,
        apply_volumetric_controls: 'true',
        requires_possession_owner_id: 'false',
        enable_possession_requests_to: 'true',
        enable_possession_requests_from: 'true',
      },
    };
  },
  emits: ['refresh', 'close'],
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    addLocation() {
      LocationsService.createLocation({
        name: this.location.name.trim(),
        // If volumetric controls are turned off default it to -1
        max_items: this.location.apply_volumetric_controls === 'true' ? parseInt(this.location.max_items, 10) : -1,
        apply_volumetric_controls: this.location.apply_volumetric_controls === 'true',
        requires_possession_owner_id: this.location.requires_possession_owner_id === 'true',
        enable_possession_requests_to: this.location.enable_possession_requests_to === 'true',
        enable_possession_requests_from: this.location.enable_possession_requests_from === 'true',
      }).then(() => {
        this.$toast.success(`Added location ${this.location.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.$emit('refresh', true);
      }).catch(err => {
        const res = err.response;
        let errorText = 'Could not add location, please refresh and try again';

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
  },
};
</script>
